$primary-color: #90c776;
$secondary-color: #25a9c2;
$featured-image: url(/images/header.jpg);

$header-background:$primary-color;

$page-header-bkg:$secondary-color;
$page-header:#FFF;

$hero-bkg:#EFEFEF;
$hero-h1-color:#25a9c2;
$hero-button-bkg:$primary-color;
$hero-button-text:white;

$toggle-icon-bar:white;
$line-color:#dddddd;

$signup-bkg: #EFEFEF;
$signup-text:$secondary-color;
$signup-button-bkg:$primary-color;
$signup-button-text:white;


/* header and navbar */
header{
  background-color: $header-background;
}

.navbar{
  margin-bottom:0;
  min-height:70px;
}
.navbar-brand {
  padding: 0;
  img {
    padding-top:10px;
  }
}
.navbar-nav>li>a {
  padding-top:25px;
  color: #FFF;
  text-transform:uppercase !important;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
}
.navbar-toggle {
  background-color:$primary-color;
  margin-right:0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px "Open Sans", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}
a{
  color:$secondary-color;
}
a:hover, a:active{
  color:$primary-color;
}
h1, h2, h3, h4, h5, h6{
  color : $secondary-color;
}

p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
main {
  padding:30px 0 0;
  h3{
    font-weight:700;
  }
  h3.contact{
    margin-top:0;
  }
}
/* footer */
footer{
  background-color:$secondary-color;
  padding: 30px 0px;
  color:#FFF;
  font-size: 14px;
  line-height: 21px;
  text-align:center;
  margin:auto;
  .container{
    max-width: 85%;
  }
  a{
    display:none;
  }
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  a {
    display:block;
    background-color: $primary-color;
    color: #FFF;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding: 20px 15px 20px;
  h1.page-title {
    text-align: left;
    max-width:1170px;
    font-size: 28px;
    line-height: 35px;
    color: $page-header;
    font-weight: 700;
    margin:0 auto;
  }
}

/*hero only needed when including the hero partial*/

section.hero {
  text-align:center;
  background-color: $hero-bkg;
  background-image: $featured-image;
  padding: 8% 0;
  min-height: 400px;
  background-size: cover;
  background-position:center;
  background-repeat: no-repeat;
  .hero_message {
    text-align: center;
    margin: auto;
    padding: 1%;
    width: 100%;
    h1 {
      font-size: 2.3em;
      line-height: 1.5em;
      font-family:'Open Sans', sans-serif;
      font-weight: 700;
      padding-bottom: 10px;
      color: $hero-h1-color;
      text-shadow:1px 1px 3px rgba(0,0,0,.5);
    }
    p, a.read-more {
      display:none;
    }
  }
}


/*home-intro is only only needed when included the signup-message partial */
section#home-intro{
  text-align:center;
  background-color:$signup-bkg;
  padding: 40px 0 60px;
  h3{
    margin-bottom: 15px;
    padding:0 10px;
    font-weight: 700;
    color: $signup-text;
    font-size:3.3rem;
  }
  p{
    max-width:420px;
    font-size:14px;
    padding-bottom:20px;
    text-align:center;
    margin:auto;
  }
  a.call-to-action {
    background-color:$signup-button-bkg;
    border: 1px solid $signup-button-bkg;
    color:$signup-button-text;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 25px;
    border-radius: 3px;
    display:inline-block;
  }
}

/* home features*/
.features{
  .col-sm-4{
    text-align:center;
    margin:auto;
    h3{
      font-weight:700;
    }
  }
}



/* site specific before_footer*/
.before-footer{
  border-top:10px solid $primary-color;
  background-color:$secondary-color;
  color:#fff;
  padding:30px 0 30px;
  font-size:12px;
  text-align: center;
  margin:40px auto 0;
  a{
    color:#fff;
    font-weight:400;
    font-size:13px;
  }
}

