/* header and navbar */
header {
  background-color: #90c776;
}

.navbar {
  margin-bottom: 0;
  min-height: 70px;
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  padding-top: 10px;
}

.navbar-nav > li > a {
  padding-top: 25px;
  color: #FFF;
  text-transform: uppercase !important;
}

.nav > li > a:focus, .nav > li > a:hover {
  background-color: transparent;
}

.navbar-toggle {
  background-color: #90c776;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background-color: white;
}

/* body, main and basic styles */
body {
  color: #666;
  font: 16px "Open Sans", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
  font-weight: 300;
}

a {
  color: #25a9c2;
}

a:hover, a:active {
  color: #90c776;
}

h1, h2, h3, h4, h5, h6 {
  color: #25a9c2;
}

p {
  margin: 0 0 20px;
}

input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input[type="reset"], input[type="button"], input[type="submit"] {
  background-color: #90c776;
  border: 1px solid #90c776;
  color: #FFF;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 20px;
  margin-top: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

main {
  padding: 30px 0 0;
}

main h3 {
  font-weight: 700;
}

main h3.contact {
  margin-top: 0;
}

/* footer */
footer {
  background-color: #25a9c2;
  padding: 30px 0px;
  color: #FFF;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: auto;
}

footer .container {
  max-width: 85%;
}

footer a {
  display: none;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.back-to-top a {
  display: block;
  background-color: #90c776;
  color: #FFF;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
}

@media screen and (max-width: 830px) {
  footer .copyright {
    padding-bottom: 10px;
    display: block;
  }
}

/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: #25a9c2 !important;
  padding: 20px 15px 20px;
}

section.page-title-wrap h1.page-title {
  text-align: left;
  max-width: 1170px;
  font-size: 28px;
  line-height: 35px;
  color: #FFF;
  font-weight: 700;
  margin: 0 auto;
}

/*hero only needed when including the hero partial*/
section.hero {
  text-align: center;
  background-color: #EFEFEF;
  background-image: url(/images/header.jpg);
  padding: 8% 0;
  min-height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

section.hero .hero_message {
  text-align: center;
  margin: auto;
  padding: 1%;
  width: 100%;
}

section.hero .hero_message h1 {
  font-size: 2.3em;
  line-height: 1.5em;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  padding-bottom: 10px;
  color: #25a9c2;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

section.hero .hero_message p, section.hero .hero_message a.read-more {
  display: none;
}

/*home-intro is only only needed when included the signup-message partial */
section#home-intro {
  text-align: center;
  background-color: #EFEFEF;
  padding: 40px 0 60px;
}

section#home-intro h3 {
  margin-bottom: 15px;
  padding: 0 10px;
  font-weight: 700;
  color: #25a9c2;
  font-size: 3.3rem;
}

section#home-intro p {
  max-width: 420px;
  font-size: 14px;
  padding-bottom: 20px;
  text-align: center;
  margin: auto;
}

section#home-intro a.call-to-action {
  background-color: #90c776;
  border: 1px solid #90c776;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 3px;
  display: inline-block;
}

/* home features*/
.features .col-sm-4 {
  text-align: center;
  margin: auto;
}

.features .col-sm-4 h3 {
  font-weight: 700;
}

/* site specific before_footer*/
.before-footer {
  border-top: 10px solid #90c776;
  background-color: #25a9c2;
  color: #fff;
  padding: 30px 0 30px;
  font-size: 12px;
  text-align: center;
  margin: 40px auto 0;
}

.before-footer a {
  color: #fff;
  font-weight: 400;
  font-size: 13px;
}
